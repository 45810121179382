* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.map-container {
  height: 60vh;
}
.sidebar {
  background-color: rgb(35 55 75 / 90%);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
  }